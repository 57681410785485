import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import RefundRequestForm from "../../components/Forms/RefundRequestForm";
import OurLocations from "../../sections/OurLocations";
import { navigate } from "gatsby-link";

const RefundRequestPage = () => {
  const successSubmitted = () => {
    navigate("/feat-members/submitted");
  };
  return (
    <Layout>
      <Seo
        title="Refund Request | FEAT Fitness"
        meta_title="Refund Request | FEAT Fitness"
        slug="feat-members/refund-request"
        noindex
      />
      <main>
        <div>
          <div className="bg-feat-lightgrey">
            <div className="max-w-lg mx-auto py-24 px-6">
              <RefundRequestForm
                headingClassNames="text-4xl text-heading uppercase text-center leading-6 font-medium text-gray-900 pb-4"
                afterSubmit={successSubmitted}
              />
            </div>
          </div>

          {/* Map and Location Details */}
          <OurLocations />
          {/* END Map and Location Details */}

          {/* Instagram Feed */}
          <InstagramFeed />
          {/* End Instagram Feed */}
        </div>
      </main>
    </Layout>
  );
};

export default RefundRequestPage;
